:root {
  --brand-color: #252b30;
  --brand-color-dark: #1d2125;
  --brand-color-darkest: #121417;
  --brand-color-red: #e41f27;
  --brand-color-red-darker: #a71519;
}

a {
  text-decoration: none;
}

.body-fixed {
  position: fixed;
}

.navbar {
  z-index: 1;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: 110px;
  /*padding: 50px 250px;*/
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgba(37, 43, 48, 1);
  padding: 0 15vw;
}

.color-red {
  color: var(--brand-color-red);
}

.navbar div {
  width: calc();
  flex: 1 1 0px;
  flex-basis: 0;
}

.cityinfra-4 {
  max-height: 25px;
}

.palvelut-2 {
  color: white;
  font-size: 20px;
  line-height: 20px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  text-align: center;
  width: fit-content;

  border: 1px solid var(--brand-color-red);
  padding: 15px 40px;
  border-radius: 5px;
  transition: 0.3s;
}

.palvelut-2:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.3s;
}

.group-163 {
  text-decoration: none;
  display: flex;
  width: min-content;
  align-items: center;
  justify-content: right;
}

.group-163 > a.active {
  color: var(--brand-color-red);
}

.group-937 {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.group-937 a {
  margin: 0 40px;
  padding-bottom: 3px;
}

.group-937 a {
  transition: text-decoration-color 300ms;
}

.nav-element:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
  transition: 0.3s;
}

.group-937 > a.active {
  color: var(--brand-color-red);
  border-bottom: 1px solid var(--brand-color-red);
  transition: 0.3s;
}

.group-937 .etusivu {
  color: white;
  font-size: 18px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.nav-element {
  color: white;
  font-size: 18px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: 0.3s;
}

.nav-element:hover {
  cursor: pointer;
}

.nav-element-selected {
  color: var(--brand-color-red);
}

.nav-element-selected:hover {
  cursor: pointer;
}

.menu-container {
  display: none;
  width: auto;
}

.menu-icon-container {
  padding: 0;
  border: none;
  background: none;
  height: auto;
  width: 50px;
}

.menu-options-container {
  overflow: hidden;
  position: fixed;
  top: 110px;
  left: 0;
  width: 100%;
  height: calc(100% - 110px);
  background-color: var(--brand-color-dark);
  z-index: 1;
}

.menu-options-container > li {
  width: 100%;
  display: flex;
}

.menu-options-container > li > a {
  font-size: 22px;
  color: white;
  width: 100%;
  text-decoration: none;
  padding: 20px 7vw;
}

.hidden {
  display: none;
}

.menu-icon {
  height: 40px;
  width: auto;
}

@media (max-width: 1300px) {
  .navbar {
    padding: 0 7vw;
  }

  .group-937 {
    display: none;
  }

  .group-163 {
    display: none;
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
}
