@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300&display=swap");

:root {
  --brand-color: #252b30;
  --brand-color-red: #e41f27;
  --brand-color-red-darker: #a71519;
}

* {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}

.page-container {
  height: 100%;
}

.page-palvelut {
  margin-top: 110px;
  background-image: url("../../img/palvelut-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 -40vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 15vw;
}

.palvelut-header-container {
}

.palvelut-header-container > h1 {
  color: white;
  font-weight: 300;
  font-size: 40px;
}

.palv-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.palv-img {
  border-radius: 5px;
  height: 22vw;
  width: 22vw;
  padding: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));

  transition: 0.4s;
}

.palv-img:hover {
  transition: 0.4s;
  transform: scale(105%);
  cursor: pointer;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35));
}

#palv-img-1 {
  background-image: url("../../img/palv-1.png");
}
#palv-img-2 {
  background-image: url("../../img/palv-2.png");
}
#palv-img-3 {
  background-image: url("../../img/palv-3.png");
}

.palv-img > h1 {
  color: white;
  font-size: 24px;
}

.arrow-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.arrow-container > a {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: var(--brand-color-red);
  transition: 0.3s;
}

.arrow-container > a:hover {
  color: var(--brand-color-red-darker);
  transition: 0.3s;
}

.arrow-container > img {
  margin-left: 10px;
  max-height: 12px;
}

.boxes-container {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.boxes-container > h1 {
  font-weight: 300;
}

.boxes-container-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.inner-box {
  margin-top: 20px;
  width: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  transition: 0.4s;
}

.inner-box:hover {
  transform: scale(105%);
  cursor: pointer;
  transition: 0.4s;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.inner-box > p {
  margin-left: 24px;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
}

.inner-box-number {
  border-radius: 10px 0 0 10px;
  height: 8vh;
  width: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  color: white;
  background-color: var(--brand-color-red);
}

@media (max-width: 1300px) {
  .page-palvelut {
    height: auto;
    padding: 0 7vw;

    background-image: none;
  }

  .palvelut-header-container {
    padding: 40px 0;
    display: flex;
  }

  .palvelut-header-container > h1 {
    color: black;
    font-size: 32px;
  }

  .palv-image-container {
    min-height: 55vh;
    flex-direction: column;
  }

  .palv-img {
    margin: 0px 0 20px 0;
    width: 100%;
    height: 86vw;
  }

  .boxes-container {
    height: auto;
    padding: 10vh 0;
  }

  .boxes-container-inner {
    flex-direction: column;
  }

  .inner-box {
    width: 100%;
    margin-top: 30px;
  }

  .inner-box > p {
    font-size: 20px;
  }
}
