@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300&display=swap");

:root {
  --brand-color: #252b30;
  --brand-color-red: #e41f27;
}

* {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}

.color-red {
  color: var(--brand-color-red);
}

body {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

.page {
  height: calc(100vh - 110px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

#first-page {
  margin-top: 110px; /* Height of navbar! */
}

#page-inner-first {
  background-image: url("../../img/image1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 65vh;
}

#second-page {
  height: 65vh;
}

#page-inner-second {
  height: calc(100vh - 110px - 65vh);
  background-color: var(--brand-color-red);
}

.page-inner {
  display: flex;
  flex-direction: row;
  padding: 0 15vw;
  align-items: center;
}

.page-inner > img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.page-inner-title {
  color: white;
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  flex-basis: 0;
  height: 55%;
  justify-content: space-around;
  justify-self: center;
}

.page-inner-title > div > p {
  width: 50%;
  font-size: 20px;
}

.page-inner-header {
  font-weight: 300;
  font-size: 60px;
}

.inner-subs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.inner-subs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inner-subs-container > img {
  max-height: 60px;
  /*margin: 0 15px 0 15px;*/
  margin-top: 0;
}

.inner-subs-text {
  color: white;
  display: flex;
  margin-left: 1.5vw;
  font-weight: 300;
  font-size: 30px;
}

.small-page {
  color: white;
  background-color: rgba(37, 43, 48, 1);
  height: auto;
  width: 100%;
  padding: 0 10vw;
  display: flex;
}

.small-page {
  width: auto;
}

.small-page-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5vh 0 5vh 0;
}

.galleria {
  font-family: Inter, sans-serif;
  text-decoration: underline;
  font-weight: 300;
  padding: 0vh 0 2vh 0;
}

.small-page-image-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  justify-content: space-between;
}

.small-page-image-container > img {
  max-width: 20%;
  max-height: 100%;
  margin: 0;
}

.page-inner-buttons-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid var(--brand-color-red);
  border-radius: 5px;
  width: fit-content;
}

.page-inner-buttons-container > a {
  color: white;
}

.page-inner-buttons-container > div,
.page-inner-buttons-container > a {
  width: 10vw;
  height: 100%;
  padding: 20px 40px;
  text-align: center;
  margin: 0;
  transition: 0.3s;
}

.button-right {
  border-radius: 0px 5px 5px 0px;
}

.button-left {
  border-radius: 5px 0px 0px 5px;
}

.button-right:hover {
  cursor: pointer;

  background-color: rgba(9, 9, 9, 0.6);

  transition: 0.3s;
}

.button-left:hover {
  cursor: pointer;
  background-color: var(--brand-color-red);
  opacity: 0.7;
  transition: 0.3s;
}

.button-marked {
  background-color: var(--brand-color-red);
  color: white;
}

.page-inner-bottom {
  overflow: hidden;

  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 0 0 15vw;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

.page-inner-bottom > img {
  height: auto;
  width: 50%;
  margin: auto;
  object-position: 0 -12vh;
}

@-moz-document url-prefix() {
  .page-inner-bottom > img {
    width: 100%;
  }
}

.bottom-text-container {
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
  justify-content: space-between;
}

.bottom-text-title {
  margin-top: 5vh;
  width: 80%;
  font-weight: 300;
  font-size: 40px;
  font-family: Inter, sans-serif;
}

.bottom-text-bread {
  width: 50%;
}

.button-ota-yhteytt {
  width: fit-content;
  color: white;
  background-color: var(--brand-color-red);
  border: 1px solid var(--brand-color-red);
  padding: 15px 40px;
  border-radius: 5px;
  transition: 0.3s;
}

.button-ota-yhteytt:hover {
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.7;
}

.button-phone-view {
  display: none;
}

@media (max-width: 1300px) {
  .page-inner {
    padding: 0 7vw;
  }

  #first-page {
    height: auto;
  }

  #page-inner-first {
    align-items: center;
    justify-content: center;
    background-position: 0 -15vh;
    background-repeat: no-repeat;
    background-size: auto 110vh;
    height: calc(100vh - 110px);
  }

  @-moz-document url-prefix() {
    #page-inner-first {
    }
  }

  .page-inner-bottom > img {
    max-height: 35vh;
    width: 100%;
    object-fit: cover;
    object-position: 0 0;
  }

  #page-inner-second {
    height: 60vh;
  }

  #page-inner-second > .inner-subs {
    flex-direction: column;
  }

  #second-page {
    margin-top: 0px; /* Height of navbar! */
    height: auto;
  }

  .inner-subs {
    height: 100%;
    justify-content: space-evenly;
  }

  .inner-subs-container {
    width: 100%;
  }

  .inner-subs-container > img {
    max-height: 45px;
  }

  .inner-subs-text {
    margin-left: 5vw;
    font-weight: 200;
    font-size: 20px;
  }

  .page-inner-header {
    font-size: 30px;
    width: 100%;
  }

  .page-inner-title {
    height: 90%;
    justify-content: space-between;
    width: 100%;
  }

  .inner-title-container {
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .page-inner-title > div > p {
    font-size: 16px;
    width: 100%;
  }

  .page-inner-buttons-container {
    width: 100%;
    position: relative;
    bottom: 0;
    font-size: 20px;
  }

  .page-inner-buttons-container > a {
    width: 50%;
    padding: 20px 0;
  }

  .page-inner-bottom {
    width: 100%;
    height: 100%;
    padding: 0 7vw 0 7vw;
  }

  .bottom-text-container {
    height: 100%;
    justify-content: space-between;
  }

  .bottom-text-container > * {
    padding: 1vh 0;
  }

  .bottom-text-title {
    font-weight: 300;
    font-size: 20px;
    width: 100%;
  }

  .bottom-text-bread {
    font-weight: 300;
    font-size: 14px;
    width: 100%;
  }

  .button-ota-yhteytt {
    display: none;
  }

  .button-phone-view {
    margin-bottom: 5vh;
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-phone-view > div {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: var(--brand-color-red);
    padding: 17px 10px;
  }

  .button-phone-view > div:hover {
    cursor: pointer;
  }

  .button-phone-view > div > a {
    color: white;
    text-decoration: none;
    font-weight: 300;
    font-size: 14px;
  }

  .page-inner-bottom {
    flex-direction: column;
  }

  .page-inner-bottom {
    padding: 0;
  }

  .bottom-text-container {
    padding: 0 7vw 0 7vw;
  }

  .page-inner-bottom > img {
    max-height: 35vh;
    object-fit: cover;
    object-position: 0 0;
  }
}

@media (max-width: 450px) {
  #page-inner-first {
    background-position: -210vw -15vh;
  }
}

@media (max-width: 370px) {
  #page-inner-first {
    background-size: 400%;
  }
}
