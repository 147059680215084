@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300&display=swap");

:root {
  --brand-color: #252b30;
  --brand-color-red: #e41f27;
  --brand-color-red-darker: #a71519;
}

* {
  padding: 0;
  margin: 0;
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}

.contact-page-container {
  height: 100%;
}

.contant-container {
  margin-top: 110px;
  margin-bottom: 15vh;
  background-image: url("../../img/contact-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 -40vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 15vw;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.contact-header-container {
}

.contact-header-container > h1 {
  color: white;
  font-weight: 300;
  font-size: 40px;
}

.contact-form-container {
  min-height: 50vh;
  display: flex;
  flex-direction: row;
}

.contact-form-left {
  max-width: 20vw;
  border-radius: 5px;
  background-color: var(--brand-color-red);
  padding: 30px;
}

.contact-form-left > div {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group-8242 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  justify-content: space-between;
}

.contact-form-left > div > h1 {
  color: white;
  font-weight: 400;
  font-size: 24px;
}

.contact-info-container {
  color: white;
  font-size: 11px;
}

.contact-form-right {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background-color: white;
}

.form-container {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-details-container {
  display: flex;
  flex-direction: column;
}

.form-details-inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.form-details-inner > div > p {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
}

.form-details-inner > div {
  padding: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

input,
textarea {
  font-weight: 300;
  font-size: 16px;
  padding: 20px;
  border: none;
  resize: none;
  background: #f0f0f0;
  border-radius: 5px;
}

textarea:focus,
input:focus {
  outline: 1px solid rgba(37, 43, 48, 0.4);
}

.form-message-container {
  padding: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-message-container > p {
  margin-bottom: 5px;
}

.form-button-container {
  padding: 0px 10px 10px 10px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-send-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 45px;

  border-radius: 5px;
  background-color: var(--brand-color-red);
  color: white;

  transition: 0.3s;
}

.form-send-button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.3s;
}

.form-send-button > p {
  font-size: 20px;
}

.form-send-button > img {
  max-height: 24px;
}

@media (max-width: 1300px) {
  .contant-container {
    margin-top: 110px;
    margin-bottom: 0;
    background-image: none;
    filter: none;
  }

  .contant-container {
    height: auto;
    padding: 0;
  }

  .contact-header-container {
    padding: 40px 0;
    display: flex;
  }

  .contact-form-container {
    flex-direction: column-reverse;
  }

  .contact-header-container {
    padding: 40px 7vw;
  }

  .contact-header-container > h1 {
    color: black;
    font-size: 32px;
  }

  .contact-form-left {
    margin-top: 7vw;
    max-width: 100%;
    height: auto;
    border-radius: 0;
  }

  .contact-form-left > div > * {
    margin: 20px 0;
  }

  .group-824-inner-2 {
    width: 100%;
    margin: 0px 0px;
  }

  .group-824-inner-2 > div {
    width: 100%;
    margin: 0;
    padding: 10px 0px;
  }

  .form-message-container {
    padding: 10px 0px;
  }

  .form-button-container {
    padding: 10px 0px;
  }

  .form-details-inner {
    flex-direction: column;
  }

  .form-details-inner > div {
    width: 100%;
    padding: 10px 0;
  }

  .form-container {
    padding: 0 7vw;

    height: auto;
  }

  .form-send-button {
    width: 100%;
  }
}
