.error-body {
  height: 100%;
}

.error-body-inner {
  margin-top: 110px;
  height: calc(100vh - 434px);
  width: 100%;
  display: flex;
  align-items: center;
}

.error-page-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-page-content-container > p {
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
}

.error-page-content-container > h1 {
  font-weight: 300;
  font-size: 96px;
}
