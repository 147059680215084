@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300&display=swap");

:root {
  --brand-color: #252b30;
  --brand-color-red: #e41f27;
}

p {
  margin: 0;
}

.color-red {
  color: var(--brand-color-red);
}

.group-16 {
  width: 100%;
  height: 35vh;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-color);
  display: flex;
  flex-direction: column;
  padding: 40px 15vw 20px 15vw;
}
.group-1095 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.group-211 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 50%;
  box-sizing: border-box;
}
.logo {
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
  padding-bottom: 25px;
}
.cityinfra {
  max-height: 25px;
}

.cityinfra-on-vuonna-202 {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
  max-width: 40%;
}

.group-348 {
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
}
.copyright-2023-all-righ {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  line-height: 14px;
  font-family: Inter, sans-serif;
  font-weight: 200;
}

.tietosuojaseloste {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  line-height: 14px;
  font-family: Inter, sans-serif;
  font-weight: 200;
}

.group-4108 {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.group-4108 > p {
  padding: 0;
  margin: 0;
}

.etusivu {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.palvelut {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.ota-yhteytt {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.group-824 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  justify-content: space-between;
  margin-bottom: 100px;
}

.group-824-inner {
  width: 100%;
  display: grid;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
}

.group-824-inner-right {
  display: flex;
  flex-direction: row;
  justify-self: center;
  max-height: fit-content;
  align-items: center;
  width: 20vw;
  max-height: min-content;
}

.group-824-inner-left {
  width: 10vw;
}

.group-824-inner-left > a {
  transition: 0.3s;
}

.group-824-inner-left > a:hover {
  opacity: 0.6;
  transition: 0.3s;
}

.footer-img {
  max-width: 40px;
  margin: 0;
}

.group-0110 {
  display: flex;
  flex-direction: column;
  height: fit-content;
  box-sizing: border-box;
}
.sposti-cityinfra-fi {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

._-0411231234 {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.osoite-1212345-kunta {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: Inter, sans-serif;
  font-weight: 300;
}

.group-824-phone {
  display: none;
}

.copyright-desktop {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

@media (max-width: 1300px) {
  .copyright-desktop {
    display: none;
  }

  .group-16 {
    height: auto;
    padding: 40px 7vw;
  }

  .group-824 {
    display: none;
  }

  .group-824-phone {
    display: block;
  }

  .cityinfra {
    max-height: none;
  }

  .group-824-inner {
    margin: 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .group-824-inner > div {
    padding: 15px 0px;
    width: 100%;
  }

  .footer-img {
    max-width: 30px;
    margin: 0 10px 0 0;
  }

  .group-1095 {
    flex-direction: column;
    width: 100%;
  }

  .group-211 {
    height: auto;
  }

  .group-1095 > div {
    width: 100%;
  }

  .logo {
    width: 55%;
    height: 100%;
    padding-bottom: 10px;
  }

  .group-824 {
    flex-direction: column;
  }

  .group-348 {
    flex-direction: column;
    margin: 25px 0px 0px 0px;
  }
}
